const button = document.getElementById("find__root");
const city = document.getElementById("city");
const street = document.getElementById("street");

if (button) {
  button.onclick = () => {
    if (!city.value || !street.value) {
      city.classList.add("input__invalid");
      street.classList.add("input__invalid");
      return;
    }
    const url = `${street.value.split(" ").join("+")}+${city.value
      .split(" ")
      .join("+")}`;
    window.open(
      `https://www.google.com/maps/dir/${url}/Runowo+6,+62-035+Runowo/`,
      "_blank"
    );
  };
}
