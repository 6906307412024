let menuItems = document.getElementById("menu-baza-wiedzy");
let menuItemsIds = [];

if (menuItems) {
  menuItems = menuItems.childNodes;
  for (let i = 0; i <= menuItems.length; i++) {
    const item = menuItems[i];
    if (item && item.firstChild) {
      menuItemsIds.push(item.firstChild);
    }
    if (
      item &&
      item.classList &&
      item.classList.contains("menu-item-has-children")
    ) {
      const itemChild = item.childNodes[2].childNodes;
      for (let a = 0; a <= itemChild.length; a++) {
        const childItem = itemChild[a];
        if (childItem && childItem.firstChild) {
          menuItemsIds.push(childItem.firstChild);
        }
      }
    }
  }
  setVisibility();
  window.addEventListener("scroll", () => {
    setVisibility();
  });
}

function setVisibility() {
  for (let i = 0; i <= menuItemsIds.length; i++) {
    const item = document.getElementById(
      menuItemsIds[i] && menuItemsIds[i].hash.substring(1)
    );
    if (item && isInViewport(item)) {
      menuItemsIds[i].classList.add("visible");
      console.log(menuItemsIds[i].parentElement);
      menuItemsIds[i].parentElement.classList.add("visible");
    } else {
      if (menuItemsIds[i]) {
        menuItemsIds[i].classList.remove("visible");
        menuItemsIds[i].parentElement.classList.remove("visible");
      }
    }
  }
}

function isInViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

// if (element) {
//   const rect = element.getBoundingClientRect();
//   const html = document.documentElement;
//   return (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <= (window.innerHeight || html.clientHeight) &&
//     rect.right <= (window.innerWidth || html.clientWidth)
//   );
// } else {
//   return false;
// }
