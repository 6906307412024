// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/

function nodeToString(node) {
  let tmpNode = document.createElement("div");
  tmpNode.appendChild(node.cloneNode(true));
  const str = tmpNode.innerHTML;
  tmpNode = node = null;
  return str;
}

const sliders = {
  home: $(".home-slider"),
  slider: $(".gallery .images")
};

sliders.slider.on("init", (e, slick) => {
  $("#count").text(slick.slideCount);
  $("#currentCount").text(slick.currentSlide + 1);
  $("#next").click(function() {
    slick.slickNext();
  });

  $("#previous").click(function() {
    slick.slickPrev();
  });
});

sliders.slider.on("afterChange", function(event, slick) {
  $("#currentCount").text(slick.currentSlide + 1);
});

sliders.slider.on("beforeChange", function(event, slick, nextSlide) {
  $("#nextImage").css(
    "background-image",
    `url('${
      nodeToString(slick.$slides[nextSlide]).match(/https?.+(jpe?g|png)/)[0]
    }')`
  );
});

sliders.home.slick({
  dots: false,
  autoplay: false
});

sliders.slider.slick({
  autoplay: true,
  dots: false,
  arrows: false
});
